import styles from './AllWork.module.scss'
import React from 'react'
import cx from 'classnames'
import Image from 'gatsby-image'

import Container from '../../layouts/Container/Container'
import Text from '../Text/Text'
import Link from '../Link/Link'
import { SelectedWork } from './../LatestWork/LatestWork'

const AllWork = ({
  allWorks,
  allCovers,
  children,
  className,
  ...restProps
}) => {
  return (
    <Container as="section" bleed className={cx(styles.root)} {...restProps}>
      <Container as="header" narrow style={{ marginBottom: 64 }}>
        <Text as="h1" heading5 secondary style={{ marginBottom: 24 }}>
          All Client Projects
        </Text>
        <Text as="p" heading1 style={{ maxWidth: '32ch', fontWeight: 700 }}>
          Making useful and beautiful digital products possible for everyone.
        </Text>
      </Container>
      <Container as="ul" narrow className={styles.workContainer}>
        {allWorks.length > 0 &&
          allWorks.map(workData =>
            allCovers.map(cover => {
              if (cover.fluid.src.includes(workData.node.frontmatter.cover)) {
                return (
                  <li className={styles.work} key={workData.node.id}>
                    <Link
                      to={workData.node.frontmatter.path.replace('/work', '')}
                    >
                      <SelectedWork
                        image={cover.fluid}
                        title={workData.node.frontmatter.title}
                        client={workData.node.frontmatter.client}
                      />
                    </Link>
                  </li>
                )
              }
            })
          )}
      </Container>
    </Container>
  )
}

export default AllWork
