import styles from './LatestWork.module.scss'
import React from 'react'
import cx from 'classnames'
import Image from 'gatsby-image'

import Text from '../Text/Text'
import Link from '../Link/Link'
import Container from '../../layouts/Container/Container'
import {
  Amoeba,
  Meridian,
  Bkkbn,
  Emina,
} from './../ClientProjects/ClientProjects'

export const SelectedWork = ({ title, image, client }) => {
  return (
    <article>
      <Image fluid={image} className={styles.thumbnail} />
      <Text
        as="h3"
        heading3
        align="center"
        style={{
          color: '#292929',
          width: '100%',
          marginBottom: 8,
          fontSize: 20,
        }}
      >
        {title}
      </Text>
      {client && (
        <Text
          as="p"
          heading5
          secondary
          align="center"
          style={{ width: '100%' }}
        >
          {client}
        </Text>
      )}
    </article>
  )
}

const LatestWork = ({
  children,
  allWork,
  allCovers,
  className,
  ...restProps
}) => {
  return (
    <Container bleed as="section" className={cx(styles.root)} {...restProps}>
      <Container as="header" narrow style={{ marginBottom: 64 }}>
        <Text heading1 as="h2">
          Selected Work
        </Text>
      </Container>
      <Container as="ul" narrow className={styles.workContainer}>
        {allWork.map(work =>
          allCovers.map(cover => {
            if (
              work.node.frontmatter.selected &&
              cover.fluid.src.includes(work.node.frontmatter.cover)
            ) {
              return (
                <li className={styles.work} key={work.node.id}>
                  <Link to={work.node.frontmatter.path.replace('/work', '')}>
                    <SelectedWork
                      image={cover.fluid}
                      title={work.node.frontmatter.title}
                      client={work.node.frontmatter.client}
                    />
                  </Link>
                </li>
              )
            }
          })
        )}
      </Container>
      <Container
        narrow
        as="footer"
        style={{ textAlign: 'center', paddingTop: 96 }}
      >
        <Link to="/work" style={{ textAlign: 'center' }}>
          See all projects
        </Link>
      </Container>
    </Container>
  )
}

export default LatestWork
