import React from 'react'
import { graphql } from 'gatsby'

import Default from '../layouts/Default/Default'
import SEO from '../utils/seo'
import AllWork from '../components/AllWork/AllWork'

const WorkPage = ({ data }) => {
  let {
    allWork: { edges: work },
    allCovers: { nodes },
  } = data
  return (
    <Default light>
      <SEO
        lang="en"
        title="Work"
        description="All client projects including UX/UI Design, Front-end web development, Back-end web development, Branding, 3D Modelling, and Animation"
        keywords={[
          `website`,
          `build`,
          `create`,
          `web`,
          `mobile`,
          `app`,
          `react`,
          `front-end`,
          `developer`,
          `programmer`,
          `bandung`,
          `agency`,
          `designer`,
          `application`,
          `react`,
        ]}
        author="Ongki Herlambang <ongki@herlambang.design>"
      />
      <AllWork allWorks={work} allCovers={nodes} />
    </Default>
  )
}

export default WorkPage

export const pageQuery = graphql`
  query workQuery {
    allWork: allMdx(
      sort: { order: DESC, fields: frontmatter___date }
      filter: { frontmatter: { path: { glob: "/work/*" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            path
            keywords
            date
            cover
            client
          }
          timeToRead
          excerpt
        }
      }
    }
    allCovers: allImageSharp(
      filter: { original: { src: { regex: "/work/" } } }
    ) {
      nodes {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
